<template>
  <div class="page-content">
    <div class="title">
      <div>添加门店</div>
      <div>
        <Button type="text" class="md-download-btn" @click="batchAdd">批量添加</Button>
        <Button type="text" class="md-download-btn" @click="syncStore">同步数据</Button>
        <Button type="text" class="md-download-btn" @click="getAuthUrl">门店授权</Button>
      </div>
    </div>
    <div class="tab">
      <base-form ref="baseForm" :btns="['search']" :formList="formList" @search="search"></base-form>
    </div>
    <div class="table" ref="tableBox">
      <IviewTable
        :height="tableHeight"
        :border="true"
        :columns="tableColumns"
        :data="tableData"
        :context="self"
        backStage
        @on-change="pageChange"
        @on-page-size-change="pageSizeChange"
        :page="page"
        @on-selection-change="onSelectionChange"
        no-data-text="没找到门店？试试同步数据之后再查询吧！"
      ></IviewTable>
    </div>
    <div class="tips">
      <div>Tips：</div>
      <div>1.已经同步过的门店将不在列表中展示，添加完之后需要在美团侧进行授权。</div>
      <div>2.添加授权时需再次授权全部已添加的门店。</div>
      <div>3.没找到门店？试试同步数据之后再查询吧！</div>
    </div>
    
  </div>
</template>

<script>
import { formatTime } from "@/utils";
import baseForm from '@/components/baseForm'
import FormRow from '@/components/formRow'
import IviewTable from '_c/iview/IviewTable'

export default {
  name: '',
  components: {
    IviewTable,
    FormRow,
    baseForm
  },
  data() {
    return {
      self: this,
      modalFormStatus: false,
      showModal: false,
      showModal2: false,
      activeStore: {},
      tableHeight:0,
      formValidate: {
        storeName: ''
      },
      formList: [
        {
          label: '门店名称',
          placeholder: '门店名称',
          type: 'input',
          model: 'storeName',
        }
      ],
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      selection: [],
      rowData: {},
      tableData: [],
      tableColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '门店名称',
          key: 'storeName',
        },
        {
          title: '门店号',
          key: 'storeId',
        },
        {
          title: '操作',
          key: 'action',
          width: 120,
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  style: { color: '#FFC300', cursor: 'pointer' },
                  on: {
                    click: () => {
                      this.addStore([params.row.storeId])
                    },
                  },
                },
                '添加门店'
              )
            ])
          },
        },
      ],
    }
  },
  mounted() {
    this.search({})
    // this.$refs.baseForm.setData(this.formValidate)
    this.getElementHeight();
    // 监听页面rezise事件
    window.addEventListener("resize", this.getElementHeight);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("resize", this.getElementHeight);
    });
  },
  methods: {
    getElementHeight() {
      const element = this.$refs.tableBox;
      const height = element.offsetHeight; // 或者使用其他的属性，如clientHeight、scrollHeight等
      console.log('Element height:', height);
      // 其他逻辑处理
      this.tableHeight = height - 90
    },
    pageChange(page){
      this.page.current = page
      this.formValidate.page = page
      this.getStoreList(this.formValidate)
    },
    pageSizeChange(pageSize){
      this.page.current = 1
      this.page.pageSize = pageSize
      this.formValidate.page = 1
      this.formValidate.pageSize = pageSize
      this.getStoreList(this.formValidate)
    },
    search(data) {
      this.page.current = 1
      let params = {
        ...data,
        page: this.page.current,
        pageSize: this.page.pageSize,
      }
      this.formValidate = params
      this.getStoreList(params)
    },
    getStoreList(params = {}) {
      this.$api.order.notNewStoreList(params).then((res) => {
        this.selection = []
        this.tableData = res.items
        this.page.total = res.count
      })
    },
    onSelectionChange(selection){
      this.selection = selection.map(v=>v.storeId)
      console.log(selection)
    },
    batchAdd(){
      if(this.selection.length==0){
        this.$Message.warning('请选择要添加的门店');
        return
      }
      this.addStore(this.selection)
    },
    addStore(storeCodes){
      this.$api.order.insertStore({storeCodes}).then(res=>{
        if (res) {
          this.$Message.success('添加成功！')
          this.getStoreList(this.formValidate)
          this.getAuthUrl()
        }
      })
    },
    // 同步门店
    syncStore(){
      this.$api.order.getStores().then(res=>{
        if (res == 'OP_SUCCESS') {
          this.$Message.success('同步成功！')
        }
      })
    },
    getAuthUrl () {
      this.$api.order.getAuthCodeUrl().then(url=>{
        window.open(url)
      })
    }
  },
}
</script>

<style lang="less" scoped>
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 28px;
  font-size: 24px;
  .md-download-btn {
    font-size: 16px;
  }
}
.tab {
  background: #ffffff;
}
.table {
  background: #ffffff;
  padding: 0 30px;
  position: relative;
  height: calc(100vh - 155px);
  /deep/.i-page.fixed {
    position: absolute;
    bottom: -40px;
  }
  .btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}
// .btn-save {
//   width: 80px;
//   height: 30px;
//   background: #85c989;
//   box-shadow: 0px 2px 2px 0px #448247, 0px 0px 1px 0px #b4ffb9;
//   font-size: 14px;
//   font-weight: bold;
//   color: #ffffff;
//   text-shadow: 0px 1px 1px #163124, 0px 3px 5px #bcffca;
//   margin-right: 11px;
//   border: none;
// }

.page-content {
  position: relative;
  .tips {
    position: absolute;
    left: 30px;
    bottom: 10px;
    color: #f00;
  }
}
</style>
