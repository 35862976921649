<template>
    <div>
        <Table
          row-key="itemNo"
          :border="true"
          :columns="tableColumns"
          :data="tableData2"
          backStage
          show-summary
          :summary-method="handleSummary" 
        ></Table>
    </div>
  </template>
  
  <script>
  import IviewTable from '_c/iview/IviewTable'
  let toYuan = (h, params, props) => {
    return h(
      'span',
      {
        props: {},
      },
      (params.row[props]/100).toFixed(2)
    )
  }
  export default {
    name: '',
    components: {
      IviewTable,
    },
    props: {
        tableData:{
            type: Array,
            default: () => []
        }
    },
    data() {
      return {
        tableColumns: [
          {
            title: '菜品名称',
            key: 'name',
            tree: true,
            align: 'left'
          },
          {
            title: '菜品规格',
            key: 'unit',
            width: '100px',
            align: 'center'
          },
          {
            title: '数量',
            key: 'count',
            width: '100px',
            align: 'center'
          },
          {
            title: '价格(元)',
            key: 'price',
            width: '100px',
            summary: true,
            align: 'center'
            // render: (h, params) => toYuan(h, params, 'amount')
          },
          {
            title: '优惠金额(元)',
            key: 'discountAmount',
            width: '120px',
            summary: true,
            align: 'center'
            // render: (h, params) => toYuan(h, params, 'receivable')
          },
          {
            title: '支付金额(元)',
            key: 'income',
            width: '120px',
            summary: true,
            align: 'center'
            // render: (h, params) => toYuan(h, params, 'receivable')
          }
        ]
      }
    },
    computed: {
      tableData2(){
        return this.tableData.map(item => {
          if (item.discountItems && item.discountItems.length > 0) {
            item.children = item.discountItems.map((v,vi) => {
              return {
                ...v,
                itemNo: v.itemNo + vi
              }
            })
            item._showChildren = true
          }
          return item
        })
      }
    },
    mounted() {
      
    },
    methods: {
      handleSummary ({ columns, data }) {
        const sums = {};
        columns.forEach((column, index) => {
          console.log(column)
            const key = column.key;
            if (index === 0) {
                sums[key] = {
                  key,
                  value: '合计'
                };
                return;
            }
            if (!column.summary) {
                sums[key] = {
                    key,
                    value: ''
                };
                return;
            }
            let values = data.map(item => Number(item[key]));
            // if (key === 'price') {
            //   values = data.map(item => Number(item.price * item.count));
            // }
            if (!values.every(value => isNaN(value)) && values.length>0) {
                const v = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                }, 0);
                sums[key] = {
                  key,
                  value: (v).toFixed(2)
                };
            } else {
                sums[key] = {
                    key,
                    value: '0.00'
                };
            }
        });
        return sums;
      }
    },
  }
  </script>
  
  <style lang="less" scoped>

  </style>
  