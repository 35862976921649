<template>
  <div class="i-page" :class="['align-' + align, fixed && 'fixed', backStage && 'back-stage']">
    <Page
      ref="page"
      v-bind="$attrs"
      :current="current"
      :pageSize="pageSize"
      :show-total="showTotal"
      :show-elevator="showElevator"
      :show-sizer="showSizer"
      @on-change="handleChange"
      @on-page-size-change="handlePageSizeChange"
    />
  </div>
</template>

<script>
// @group iview
import { debounce } from '@/utils'
export default {
  name: 'IviewPage',
  props: {
    // 说明：是否使用后台样式，不传默认是不使用
    // 用法：<IviewPage back-stage></IviewPage>
    backStage: {
      type: Boolean,
    },
    // 说明：pager的位置，默认右下角
    // 可选：right，center，left
    // 用法：<IviewPage align="right"></IviewPage>
    align: {
      type: String,
      default: 'right',
    },
    // iview中show-total，show-elevator，show-sizer默认为false
    // 环保项目默认都设置为true，仍可通过传入props修改，用法与官网API相同（注意：在vue模板中，props传参使用show-total和showTotal效果是一样的）
    showTotal: {
      type: Boolean,
      default: true,
    },
    showElevator: {
      type: Boolean,
      default: true,
    },
    showSizer: {
      type: Boolean,
      default: true,
    },
    // 固定在右下角
    fixed: {
      type: Boolean,
    },
    // 用于监听current，手动修改显示页码
    current: {},
    pageSize: {},
    page: {},
  },
  data() {
    return {
      selfPage: {},
    }
  },
  watch: {
    current: {
      handler(n) {
        this.$nextTick(() => {
          this.$refs.page.currentPage = n
        })
      },
      immediate: true,
    },
    pageSize: {
      handler(n) {
        this.$nextTick(() => {
          this.$refs.page.currentPageSize = n
        })
      },
      immediate: true,
    },
  },
  methods: {
    // 劫持 on-change 事件
    handleChange(e) {
      // console.log("handleChange----", e);
      this.selfPage.pageNum = e || 1
      this.$emit('on-change', e)
      this.handlePageChange()
    },
    // 劫持 on-page-size-change 事件
    handlePageSizeChange(e) {
      // console.log("handlePageSizeChange---", e);
      this.selfPage.pageSize = e || 10
      this.$emit('on-page-size-change', e)
      this.handlePageChange()
    },
    // 添加 on-page-change 事件
    handlePageChange: debounce(function () {
      // console.log('handlePageChange----', this.selfPage)
      this.$emit('on-page-change', this.selfPage)
    }, 200),
  },
  created() {
    this.selfPage = {
      pageNum: this.current || 1,
      pageSize: this.pageSize || 10,
    }
    // console.log("created-page", this.current, this.pageSize);
  },
}
</script>

<style lang="less" scoped>
@import './config/input';
@text-color: #676f69; /** 字体颜色 */
@page-size: 32px; /** 页码盒子尺寸 */
/** 页码盒子 */
.page-box {
  // .border(#233651);
  color: @text-color;
  min-width: @page-size;
  height: @page-size;
  line-height: @page-size;
  border-radius: 3px;
  font-size: 14px;
  box-shadow: 3px 4px 4px -1px #c1cbd7;
}
// 这里不使用iview-page的原因是已有全局的iview-page样式
.i-page {
  display: flex;
  align-items: center;
  &.fixed {
    position: fixed;
    // right: 20px;
    bottom: 10px;
    // width: 100%;
    
  }
  &.align-center {
    justify-content: center;
  }
  &.align-left {
    justify-content: flex-start;
  }
  &.align-right {
    justify-content: flex-end;
  }
  /deep/.ivu-page {
    // 显示总页数
    .ivu-page-total {
      color: @text-color;
    }
    // 上一页、下一页
    .ivu-page-prev,
    .ivu-page-next {
      .page-box;
      // background: #3a3a3a;
      // background: #172746;
      // background: #fff;
      a {
        // color: #fff;
        // color: rgba(255, 255, 255, 0.6);
        color: #676f69;
      }
      &:hover {
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5), 0px 1px 4px 0px rgba(127, 127, 127, 0.5);
      }
      &.ivu-page-disabled {
        box-shadow: 0px 1px 4px 0px rgba(127, 127, 127, 0.5);
        opacity: 0.4;
        border: 1px solid #4e4e4f;
      }
    }
    // 页码
    .ivu-page-item {
      .page-box;
      // .bg(rgba(3, 26, 59, 1));
      // box-shadow: 0px 0px 4px 0px rgba(148, 240, 251, 0.3),
      //   0px 0px 8px 0px rgba(0, 7, 17, 0.8);
      box-shadow: 3px 4px 4px -1px #c1cbd7;
      > a {
        color: @text-color;
      }
      &:hover {
        > a {
          // color: @main-color;
          color: #66bc7a;
        }
      }
      &.ivu-page-item-active {
        // .border(@main-color);
        border: 1px solid #66bc7a;
        // box-shadow: 0px 0px 4px 0px rgba(133, 247, 220, 0.3),
        //   0px 0px 8px 0px rgba(0, 0, 0, 0.48);
        background: linear-gradient(180deg, #f4fff5 0%, #ffffff 100%);
        box-shadow: 3px 4px 4px -1px #c8ecd7, 0px 3px 4px 1px #f0f9f4;
        a {
          // color: @main-color;
          color: #66bc7a;
        }
      }
    }
    // page选项
    .ivu-page-options {
      color: #d3d6d9;
      .options-box {
        .page-box;
        // .bg(rgba(3, 26, 59, 1));
        // box-shadow: 0px 0px 8px 0px rgba(0, 7, 17, 0.8);
        box-shadow: 3px 4px 4px -1px #c1cbd7;
        .border(#D9D9D9);
      }
      // xx条/页
      .ivu-page-options-sizer {
        .ivu-select {
          &.ivu-select-visible {
            .ivu-select-selection {
              box-shadow: 3px 4px 4px -1px #c1cbd7;
              .border(#D9D9D9);
            }
          }
          .ivu-select-selection {
            .options-box;
            .ivu-select-selected-value {
              font-size: 14px;
            }
          }
          .ivu-select-dropdown {
            .select-dropdown{
            }
            background: #fff;
            box-shadow: 0px 9px 28px 8px rgba(193, 203, 215, 0.26), 0px 6px 16px 0px rgba(193, 203, 215, 0.26),
              0px 3px 6px -4px rgba(193, 203, 215, 0.26);
            border-radius: 6px;
          }
        }
      }
      // 跳至xx页
      .ivu-page-options-elevator {
        input {
          .options-box;
          text-align: center;
          &:focus {
            box-shadow: 0px 0px 6px 0px rgba(133, 247, 220, 0.3), 0px 0px 8px 0px rgba(0, 0, 0, 0.48);
            .border(@main-color);
          }
        }
      }
    }
    // 省略号
    .ivu-page-item-jump-prev,
    .ivu-page-item-jump-next {
      .page-box;
      background: rgba(255, 255, 255, 0.1);
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.48);
      a,
      i {
        color: #d3d6d9;
      }
      &:hover {
        a,
        i {
          color: @main-color;
        }
      }
    }
    // 禁用
    // .ivu-page-disabled {
    //   // .page-box-view-disabled();
    //   a {
    //     color: fade(@text-color, 20%);
    //   }
    // }
    // 简洁 分页
    &.ivu-page-simple {
      .ivu-page-simple-pager input {
        background: transparent;
        border: none;
        color: @text-color;
        margin: 0;
        text-align: right;
        padding: 0;
      }
      .ivu-page-next {
        margin-left: 16px;
      }
    }
  }
}
</style>
