<template>
  <div>
    <div class="title">
      <div>财务账号关联门店</div>
      <div>
        <Button type="text" class="md-download-btn" @click="batchAdd">添加账号</Button>
        <!-- <Button type="text" @click="GetUserStoreInfo">同步账号</Button> -->
      </div>
    </div>
    <div class="tab">
      <!-- <base-form ref="baseForm" :btns="['search']" :formList="formList" @search="search"></base-form> -->
    </div>
    <div class="table" ref="tableBox">
      <IviewTable
        :height="tableHeight"
        :border="true"
        :columns="tableColumns"
        :data="tableData"
        :context="self"
        backStage
        @on-change="pageChange"
        @on-page-size-change="pageSizeChange"
      ></IviewTable>
    </div>

    <Modal
      v-model="showModal"
      :mask-closable="false"
      footer-hide
      title="设置门店"
    >
      <div>
        <!-- <base-form ref="baseForm" :btns="['search']" :formList="formList" @search="search"></base-form> -->
        <IviewTable
          :height="500"
          :border="true"
          :columns="storeColumns"
          :data="storeData"
          :context="self"
          backStage
          @on-selection-change="onSelectionChange"
        ></IviewTable>
        <form-row>
          <div style="text-align: right;margin-top: 20px;">
            <Button type="default" style="margin-right: 10px;" class="btn-save" @click="showModal = false">取消</Button>
            <Button type="primary" class="btn-save" @click="seveSet">保存</Button>
          </div>
        </form-row>
      </div>

    </Modal>

    <Modal
      v-model="showModal2"
      :mask-closable="false"
      footer-hide
      title="修改密码"
    >
      <update-pwd ref="UpdatePwd" :userName="activeStore.userName" @close="showModal2=false"></update-pwd>
    </Modal>

    <Modal
      v-model="showModal3"
      :mask-closable="false"
      footer-hide
      title="添加账号"
    >
      <add-user ref="AddUser" @close="getUserList"></add-user>
    </Modal>
  </div>
</template>

<script>
import { formatTime } from "@/utils";
import { mapState, mapMutations } from 'vuex'
import baseForm from '@/components/baseForm'
import FormRow from '@/components/formRow'
import IviewTable from '_c/iview/IviewTable'
import UpdatePwd from '@/components/password/update-pwd2.vue'
import AddUser from '@/components/password/add-user.vue'
export default {
  name: '',
  components: {
    IviewTable,
    UpdatePwd,
    AddUser,
    FormRow,
    baseForm
  },
  data() {
    return {
      self: this,
      modalFormStatus: false,
      showModal: false,
      showModal2: false,
      showModal3: false,
      activeStore: {},
      tableHeight:0,
      formValidate: {
        storeName: ''
      },
      formList: [
      ],
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      rowData: {},
      tableData: [],
      tableColumns: [
        {
          title: '账号名称',
          key: 'userName',
          width: 200
        },
        {
          title: '关联门店',
          key: 'storeName',
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 200,
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  style: { color: '#FFC300', cursor: 'pointer', marginRight: '20px' },
                  on: {
                    click: () => {
                      this.updateRate(params.row)
                    },
                  },
                },
                '设置门店'
              ),
              h(
                'span',
                {
                  style: { color: '#FFC300', cursor: 'pointer' },
                  on: {
                    click: () => {
                      this.updatePassword(params.row)
                    },
                  },
                },
                '修改密码'
              )
            ])
          },
        },
      ],
      // 门店列表
      page2: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      storeColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '门店名称',
          key: 'storeName'
        }
      ],
      storeData: [],
      selectStore: []
    }
  },
  computed: {
      ...mapState(['UserInfo','_storeList']),
  },
  mounted() {
    this.search()
    // this.$refs.baseForm.setData(this.formValidate)
    this.getElementHeight();
    // 监听页面rezise事件
    window.addEventListener("resize", this.getElementHeight);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("resize", this.getElementHeight);
    });
  },
  methods: {
    getElementHeight() {
      const element = this.$refs.tableBox;
      const height = element.offsetHeight; // 或者使用其他的属性，如clientHeight、scrollHeight等
      console.log('Element height:', height);
      // 其他逻辑处理
      this.tableHeight = height - 40
    },
    pageChange(page){
      this.page.current = page
      this.formValidate.page = page
      this.getUserList(this.formValidate)
    },
    pageSizeChange(pageSize){
      this.page.current = 1
      this.page.pageSize = pageSize
      this.formValidate.page = 1
      this.formValidate.pageSize = pageSize
      this.getUserList(this.formValidate)
    },
    search(data) {
      this.page.current = 1
      let params = {
        ...data,
        page: this.page.current,
        pageSize: this.page.pageSize,
      }
      this.formValidate = params
      this.getUserList(params)
    },
    getUserList(params = {}) {
      this.showModal3 = false
      this.$api.order.GetUserStoreList(params).then((res) => {
        res.forEach(v=>{
          v.storeName = "-"
          if (v.storeIds) {
            v.storeIds = v.storeIds.split(',').filter(v => v)
            v.storeName = v.storeIds.map(item => {
              let store = this._storeList.find(v => v.value == item)
              return store ? store.label : item
            }).join('，')
            
          }
          
        })

        this.tableData = res
        // this.page.total = res.count
      })
    },
    updatePassword(row){
      this.showModal2 = true
      this.activeStore = row
    },
    updateRate(row){
      this.showModal = true
      this.page2.current = 1
      this.page2.pageSize = 10
      this.activeStore = row
      this.selectStore = row.storeIds
      this.getStoreList()
    },
    pageChange2(page){
      this.page2.current = page
      this.getStoreList()
    },
    pageSizeChange2(pageSize){
      this.page2.current = 1
      this.page2.pageSize = pageSize
      this.getStoreList()
    },
    getStoreList(){
      let { current, pageSize } = this.page2
      // this._storeList.slice((current - 1) * pageSize, current * pageSize)
      this.storeData = this._storeList.map(item => {
        return {
          storeName: item.label,
          storeCode: item.value,
          _checked: this.selectStore.includes(String(item.value)) ? true : false
        }
      })
      this.page2.total = this._storeList.length
    },
    onSelectionChange(selection){
      this.selectStore = selection.map(v=>v.storeCode)
    },
    seveSet(){
      let dataJson = {
        userName: this.activeStore.userName,
        storeIds: this.selectStore.join(',')
      }
      this.$api.order.UpdateUserStore(dataJson).then(res=>{
        if(res.code==200){
          this.$Message.success('设置成功！');
          this.showModal = false
          this.getUserList()
        }
      })
    },
    batchAdd(){
      this.showModal3 = true
      this.$refs.AddUser.initData()
    },
    GetUserStoreInfo(){
      this.$api.order.GetUserStoreInfo()
    }
  },
}
</script>

<style lang="less" scoped>
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 28px;
  font-size: 24px;
  .md-download-btn {
    font-size: 16px;
  }
}
.tab {
  background: #ffffff;
}
.table {
  background: #ffffff;
  padding: 0 30px;
  position: relative;
  height: calc(100vh - 155px);
  /deep/.i-page.fixed {
    position: absolute;
    bottom: -40px;
  }
  .btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}
// .btn-save {
//   width: 80px;
//   height: 30px;
//   background: #85c989;
//   box-shadow: 0px 2px 2px 0px #448247, 0px 0px 1px 0px #b4ffb9;
//   font-size: 14px;
//   font-weight: bold;
//   color: #ffffff;
//   text-shadow: 0px 1px 1px #163124, 0px 3px 5px #bcffca;
//   margin-right: 11px;
//   border: none;
// }
</style>
