<template>
  <Form ref="pwdForm" :model="form" :rules="rules" @keydown.enter.native="handleSubmit">
    <FormItem prop="userName">
      <Input type="text" v-model="form.userName" placeholder="请输入用户名"> </Input>
    </FormItem>
    <FormItem prop="newPassword">
      <Input type="password" v-model="form.newPassword" placeholder="请输入密码"> </Input>
    </FormItem>
    <FormItem>
      <Button @click="handleSubmit" type="primary" long>添加</Button>
    </FormItem>
  </Form>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'PwdForm2',
  props: {
  },
  data () {
    const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('确认密码不能为空'));
        } else {
          if (this.form.newPassword !== value) {
            callback(new Error('两次密码不一致'));
          }else{
            callback();
          }
        }
    };
    return {
      form: {
        userName: '',
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
        pattern: 0 //0：添加账号，1修改账号
      },
      rules:{
        userName: [
          { required: true, message: '用户名不能为空', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapState(['UserInfo'])
    
  },
  methods: {
    initData () {
      this.form.userName = ""
      this.form.newPassword = ""
    },
    handleSubmit () {
      this.form.newPasswordConfirm = this.form.newPassword
      this.$refs.pwdForm.validate((valid) => {
        if (valid) {
          this.form.orgId = this.orgId
          this.$api.login.AddUser(this.form).then(res=>{
            if(res.code==200){
              this.$Message.success('添加成功！')
              this.$emit('close')
            }else{
              this.$Message.error( res.message || '添加失败！')
            }
          })
        }
      })
    }
  }
}
</script>
