/**
 * 登录模块接口
 * name：接口名称
 * url：接口api
 * method：接口请求方法，默认GET
 * defaultParams：默认参数
 */
 export default [
  {
    name: 'getToken',
    url: '/api/Store/login',
    method: 'POST'
  },
  {
    name: 'setPassword',
    url: '/api/Store/SetPassword',
    method: 'POST'
  },
  {
    /** 新增/编辑账号 */
    name: 'AddUser',
    url: '/api/Store/AddUser',
    method: 'POST'
  }
]


