<!-- 
  对echart进行封装，只需要传入option即可。
  对option进行监听，图表根据数据动态变化。
 -->
<template>
  <div class="echart" ref="echart" />
</template>

<script>
const echarts = require("echarts");
import { debounce } from "@/utils";
const animationDuration = 6000;
export default {
  name:'v-chart',
  props: {
    // 配置项
    option: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    // watch option 当数据变化时自动重绘图表（注意传入的option必须为响应式数据才起效，否则需要手动执行setOption方法）
    option: {
      handler() {
        if (this.chart) {
          this.setOption();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.echart);
      this.setOption(this.option, animationDuration);
      // this.chart.on("click", params => this.$emit("chartClick", params));
    },
    setOption(data) {
      data = data || this.option;
      data && this.chart.setOption(data, animationDuration);
    },
  },
  mounted() {
    this.initChart();
    this.resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.resizeHandler);
    this.chart.on("mousemove", params=> {
      let {clientX,clientY}=params.event.event
      this.$emit('mousemove',{index:params.dataIndex,x:clientX+40,y:clientY-150})
    });
  },
  beforeDestroy() {
    if (this.chart) {
      window.removeEventListener("resize", this.resizeHandler);
      this.chart.dispose();
      this.chart = null;
    }
  },
};
</script>

<style lang="less" scoped>
.echart {
  width: 600px;
  height: 400px;
}
</style>
