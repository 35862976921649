<template>
  <div class="bg">
    <!-- <Header></Header> -->
    <div class="layout">
      <sidebarNav class="sidebar" />
      <div class="main-container">
        <breadcrumb />
        <router-view class="view"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './modules/Header'
import sidebarNav from './modules/aside-menu'
import breadcrumb from './modules/breadcrumb'
export default {
  name: 'Layout',
  components: {
    Header,
    sidebarNav,
    breadcrumb
  }
}
</script>

<style lang="less" scoped>
.bg {
  background-color: #e9edf5;
}
.layout {
  display: flex;
  width: 100%;
  // padding-top: 1px;
  // margin-top: 64px;
}
.main-container {
  width: 0;
  flex: 1;
  padding:0 0 0 10px;
  .view{
    background: #fff;
    // height: calc(100vh - 120px);
    height: calc(100vh - 47px);
    overflow-y: auto;
    width: 100%;
  }
}
</style>
