import axios from 'axios'
import Vue from 'vue'
/**
 * 格式化时间
 * Date对象转化为字符串形式
 * */
export const formatTime = function(time, s1 = '-', s2 = ':') {
  if (!time || !(time instanceof Date)) {
    time = new Date()
  }
  var y = time.getYear() + 1900
  var m = time.getMonth() + 1
  var d = time.getDate()
  var H = time.getHours()
  var M = time.getMinutes()
  var S = time.getSeconds()
  var dateStr = y + s1 + (m > 9 ? m : '0' + m) + s1 + (d > 9 ? d : '0' + d)
  var timeStr = (H > 9 ? H : '0' + H) + s2 + (M > 9 ? M : '0' + M) + s2 + (S > 9 ? S : '0' + S)
  return [dateStr + ' ' + timeStr, dateStr, timeStr]
}

export const getTime = function(time, hours=[0,0,0,0]) {
  // 创建一个新的Date对象，默认为当前日期和时间
  if (!time || !(time instanceof Date)) {
    time = new Date()
  }
  // 将时、分、秒和毫秒都设置为0
  time.setHours(...hours);
  // 输出0点的时间戳
  console.log(time.getTime());
  // 直接输出格式化的时间
  return time
}

// 导出文件
export const exportExcel = ({ url, params, fileName, method = 'post', body, fileType, intercept }) => {
  return axios[method](
    url,
    { ...body, params },
    {
      responseType: 'blob'
    }
  ).then(res => {
    let url = window.URL.createObjectURL(
      new Blob([res], {
        type: fileType || 'application/vnd.ms-excel;charset=utf-8'
      })
    )
    let link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', fileName || '文件.xlsx')
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(link.href)
    document.body.removeChild(link)
  })
}

/**
 * 函数防抖精简版
 * @param {Function} func 回调函数
 * @param {Number} timeout 时间
 */
let time = null
export function debounce1(fun, t = 100) {
  console.log(111)
  if (time) clearTimeout(time)
  time = setTimeout(() => {
    fun()
  }, t)
}

/**
 * 函数防抖优化版
 * @param {Function} fn 回调函数
 * @param {Number} delay 延迟时间
 */
export function debounce(fn, delay = 1000) {
  var timeoutID = null
  return function() {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function() {
      fn.apply(that, args)
    }, delay)
  }
}

/**
 * 获取vue组件的dom元素
 * @param {component} component vue组件
 * @param {Object} propsData vue组件props传值
 * @returns {Document} dom节点
 */
export const getEl = (component, propsData = {}) => {
  // 利用Vue.extend扩展vue组件
  const comp = Vue.extend(component)
  // 此处实例化
  const domEl = new comp({
    el: document.createElement('div'),
    // store,
    // router,
    propsData
  })
  // domEl.show = true;
  return domEl.$el
}
export const getEl2 = (el) => {
  const comp = Vue.extend({
    render(h) {
      return <div>{el}</div>
    }
  })
  // 此处实例化
  let vm = new comp({
    el: document.createElement('div'),
  })
  // vm.$mount('#app')
  return vm.$el
}

export function deepClone (obj) {
  let objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object") {
      for (var key in obj) {
          if (obj.hasOwnProperty(key)) {
              //判断ojb子元素是否为对象，如果是，递归复制
              if (obj[key] && typeof obj[key] === "object") {
                  objClone[key] = deepClone(obj[key]);
              } else {
                  //如果不是，简单复制
                  objClone[key] = obj[key];
              }
          }
      }
  }
  return objClone;
};

// getEl2()
