<template>
  <div class="admin-header">
    <!-- <div class="title">
      <span class="name" data-content="后台管理平台">后台管理平台</span>
    </div>
    <i class="ivu-icon ivu-icon-checkmark"></i> -->
    <div class="toolBar">
      <Dropdown style="margin-left: 20px">
        <div style="color: #fff;display: flex;align-items: center;">
          <div class="ellipsis" style="max-width: 200px;">
            {{UserInfo.userName}}
          </div>
          <Icon type="md-arrow-dropdown" />
        </div>
        <Dropdown-menu slot="list">
          <Dropdown-item @click.native="showModal=true" v-if="UserInfo && UserInfo.role == 'admin'">修改密码</Dropdown-item>
          <Dropdown-item @click.native="loginOut">退出登录</Dropdown-item>
        </Dropdown-menu>
      </Dropdown>
    </div>

    <Modal
      v-model="showModal"
      :mask-closable="false"
      footer-hide
      title="修改密码"
    >
      <update-pwd ref="UpdatePwd" @close="showModal = false"></update-pwd>
    </Modal>


  </div>
</template>

<script>
import { mapState } from 'vuex'
import UpdatePwd from '@/components/password/update-pwd2.vue'
export default {
  name: 'adminHeader',
  components:{ UpdatePwd },
  data(){
    return {
      showModal: false
    }
  },
  computed:{
    ...mapState(['UserInfo']),
  },
  methods: {
    goBig() {
    },
    goData() {
    },
    loginOut() {
      this.$api.logout()
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.ivu-dropdown-rel a {
  font-size: 14px;
  font-weight: bold;
  // color: #ffffff !important;
}
.admin-header {
  width: 100%;
  height: 64px;
  line-height: 64px;
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: 0;
  z-index: 999;
  .title {
    flex: 0.3;
    text-align: left;
    padding-left: 20px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 3px;
    color: #333;
    // text-shadow: 0px 2px 4px #173060;
    // background: angular-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    .name {
      position: relative;
      font-size: 24px;
      font-weight: bold;
      line-height: 31px;
      letter-spacing: 3px;
      margin-left: 10px;
      margin-right: 4px;
      &::after {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        content: attr(data-content);
        background: angular-gradient(180deg, #ffffff 0%, #ffffff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: none;
      }
    }
  }
  .toolBar {
    flex: 0.7;
    display: flex;
    justify-content: flex-end;
    // padding: 0 20px 0 0;
  }
  .btn {
    width: 102px;
    height: 28px;
    background: linear-gradient(180deg, #98d3a4 0%, #59bc70 100%);
    box-shadow: 0px 2px 2px 0px #354a4b;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    font-family: PangMenZhengDao;
    color: #eff4f6;
    line-height: 16px;
    text-shadow: 0px 3px 5px #bcffca, 0px 1px 1px #163124;
  }
}
.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>
