/*
 * @Description: 前端账号管理
 * @Author: rhd
 * @Date: 2024-09-03 09:51:44
 * @LastEditors: rhd
 * @LastEditTime: 2024-12-25 11:02:48
 */
let userList = [{
  userName:'admin',
  password:'admin@2024',
  role:'store',
  menuList:[{
    title:'订单管理',
    children: ['店内订单明细','综合营业统计']
  }]
},{
  userName:'admin2',
  password:'admin@2024',
  role:'admin',
  isAdmin: true,
  menuList:[{
    title:'订单管理',
    children: ['店内订单明细(原数据)','店内订单明细','综合营业统计','综合营业统计(原数据)']
  },{
    title:'门店管理',
    children: ['门店设置','添加门店','门店关联']
  }]
},{
  userName:'admin2',
  password:'admin@2024',
  role:'finance',
  isAdmin: true,
  menuList:[{
    title:'订单管理',
    children: ['店内订单明细(原数据)','店内订单明细','综合营业统计','综合营业统计(原数据)']
  },{
    title:'门店管理',
    children: ['门店设置']
  }]
}]
export default userList;

