/*
 * @Description: 需要权限的路由
 * @Author: rhd
 * @Date: 2024-09-03 09:51:44
 * @LastEditors: rhd
 * @LastEditTime: 2024-12-25 10:39:06
 */
import Layout from '_c/layout/index'
const dynamicRoutes = [
  // 数据大屏管理模块
  {
    path: '/manage',
    component: Layout,
    meta: {
      title: '订单管理',
      icon: 'logo-buffer',
    },
    children: [
      {
        path: 'original-order-details',
        component: () => import('@/views/orderManage/original-order-details.vue'),
        name: 'original-order-details',
        meta: {
          title: '店内订单明细(原数据)',
          icon: 'ios-copy',
        },
      },
      {
        path: 'order-details',
        component: () => import('@/views/orderManage/order-details.vue'),
        name: 'order-details',
        meta: {
          title: '店内订单明细',
          icon: 'ios-copy',
        },
      },
      {
        path: 'original-business-statistics',
        component: () => import('@/views/orderManage/original-business-statistics.vue'),
        name: 'original-business-statistics',
        meta: {
          title: '综合营业统计(原数据)',
          icon: 'ios-copy',
        },
      },
      {
        path: 'business-statistics',
        component: () => import('@/views/orderManage/business-statistics.vue'),
        name: 'business-statistics',
        meta: {
          title: '综合营业统计',
          icon: 'ios-copy',
        },
      }
    ],
  },
  {
    path: '/storeManage',
    component: Layout,
    meta: {
      title: '门店管理',
      icon: 'logo-buffer',
    },
    children: [
      {
        path: 'storeList',
        component: () => import('@/views/storeManage/storeList.vue'),
        name: 'storeList',
        meta: {
          title: '门店设置',
          icon: 'ios-copy',
        },
      },
      {
        path: 'addStore',
        component: () => import('@/views/storeManage/addStore.vue'),
        name: 'addStore',
        meta: {
          title: '添加门店',
          icon: 'ios-copy',
        },
      },
      {
        path: 'userList',
        component: () => import('@/views/storeManage/userList.vue'),
        name: 'userList',
        meta: {
          title: '门店关联',
          icon: 'ios-copy',
        },
      }
      
    ],
  }
]
export default dynamicRoutes
