<template>
  <div class="aside" :class="slide ? 'w1' : 'w2'">
    <!-- <Icon class="md-apps" type="md-apps" @click="shrinkClick"/> -->
    <Header v-if="slide"></Header>
    <Menu accordion :active-name="currentRoute" :open-names="[currentMenu]" @on-select="menuSelect">
      <div class="menu-container" :class="slide ? 'w272' : 'w62'">
        <template v-for="(item, index) in showMenuList">
          <Submenu :name="item.path" :key="index" v-if="item.children && item.children.length > 0">
            <template slot="title">
              <Icon :type="item.meta.icon" />
              {{ item.meta.title }}
            </template>
            <Menu-item
              v-for="(item1, index1) in item.children"
              :key="index + index1"
              :name="item.path + '/' + item1.path"
              :to="item.path ? `${item.path}/${item1.path}` : item1.path"
              target="_self"
            >
              <!-- v-show="$hasPermission(item1.meta.code)" -->
              <div class="circle"></div>
              <Icon :type="item1.meta.icon" />
              {{ item1.meta.title }}
            </Menu-item>
          </Submenu>
          <Menu-item v-else :name="item.path" :key="index">{{ item.meta.title }}</Menu-item>
        </template>
      </div>
    </Menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { deepClone } from '@/utils'
import Header from './Header' 
import menuList from '@/router/dynamic-router'
export default {
  name: 'asideMenu',
  components: {
    Header,
  },
  data() {
    return {
      slide: true,
      collapse: false,
      slideList: [],
      menuList
    }
  },
  computed: {
    ...mapState(['UserInfo']),
    currentRoute() {
      return location.hash.replace('#', '')
    },
    currentMenu() {
      return '/' + location.hash.replace('#', '').split('/')[1]
    },
    showMenuList(){
      // UserInfo
      let menuList = []
      console.log(this.UserInfo.menuList)
      try {
        let menuListDeep = deepClone(this.menuList)
        this.UserInfo.menuList.forEach(item=>{
          console.log(menuListDeep)
          let match1 = menuListDeep.find(v=>v.meta.title == item.title)
          if(match1){
            match1.children = match1.children.filter(v=>item.children.includes(v.meta.title))
            menuList.push(match1)
          }
        })
      } catch (error) {
        
      }
      console.log(menuList)
      return menuList
    }
  },
  created() {
    this.$bus.$on('setSlide', (bool) => {
      this.slide = bool
    })
  },
  methods: {
    menuSelect(name) {
    },
    shrinkClick() {
      this.slide = !this.slide
      this.$bus.$emit('setSlide', this.slide)
    }
  }
}
</script>
<style lang="less">
.ivu-menu-light,
.ivu-menu-submenu {
  background: transparent !important;
  width: 215px !important;
}
</style>
<style lang="less" scoped>
.aside {
  // padding-top: 72px;
  // width: 62px;
  position: relative;
}
.w1 {
  width: 242px;
}
.w2 {
  width: 62px;
}
/deep/.ivu-menu-vertical.ivu-menu-light:after {
  width: 0;
}
.md-apps {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 9999;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}
.menu-container {
  padding-top: 72px;
  height: 100vh;
  overflow-y: auto;
  //   width: 272px;
  background: #001529;
  //   background-size: contain;
  // box-shadow: 14px 0px 30px 0px #e5e9f3, -6px -6px 17px 0px #ffffff;
  transition: all 0.3s;
  position: relative;
}
.w272 {
  width: 242px;
}
.w62 {
  width: 62px;
  background-size: cover;
  img {
    transform: rotateY(0);
    left: 20px;
  }
}
/deep/.ivu-menu-submenu-title {
  text-align: left;
  padding: 0 0px 0px 23px !important;
}

/deep/.ivu-menu-submenu {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 800;
  color: #c1c1c1;
}
/deep/.ivu-menu-opened {
  margin-bottom: 10px;
}
/deep/.ivu-menu-submenu-title {
  position: relative !important;
  height: 22px;
  line-height: 22px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .img {
    width: 20px;
    height: 18px;
    background: url('~_img/menu/one-Level-normal.png') no-repeat;
    margin: 5px 24px 0 10px;
  }
}
.title-icon {
  float: left;
  margin-top: 2px;
  margin-right: 17px;
  margin-left: 20px;
}
/deep/.ivu-menu-item:hover,
/deep/.ivu-menu-submenu-title:hover {
  color: #FFC300 !important;
}
/deep/.ivu-menu-item-active:not(.ivu-menu-submenu):after {
  width: 0 !important;
}
/deep/.ivu-menu-item-active:not(.ivu-menu-submenu) {
  background: transparent !important;
  color: #FFC300 !important;
}

/deep/.ivu-menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  padding: 0 !important;
  .circle {
    width: 0;
    border-radius: 50%;
    margin: 0 0 0 45px;
  }
  &:nth-child(1) {
    margin-top: 10px;
  }
}
/deep/.ivu-menu-item-active {
  & > .circle {
    background: #FFC300 !important;
  }
}
/deep/.ivu-menu-child-item-active {
  color: #FFC300 !important;
  .img {
    background: url('~_img/menu/one-level-active.png') no-repeat !important;
  }
  
}
/deep/.ivu-menu-vertical .ivu-menu-submenu-title-icon {
  right: 0;
}
</style>
